<template>
  <div class="reports">
    <ReportWinloseComponent
      :ref-key-text="refKeyText"
      :title="title"
      :url-path="urlPath"
      :route-name="routeName"
      :is-simple="true"
    />
  </div>
</template>

<script>
import ReportWinloseComponent from '@/components/ReportWinloseComponent.vue'

export default {
  components: {
    ReportWinloseComponent,
  },
  data() {
    return {
      refKeyText: 'Username',
      title: 'W/L Agent (Simple)',
    }
  },
  computed: {
    isTest() {
      return String(this.$route.path).startsWith('/test/')
    },
    urlPath() {
      const apiVersion = this.isTest ? 'test' : 'v2'

      return `/reports/${apiVersion}/agent_winlose_simple`
    },
    routeName() {
      const name = 'reports-agent-winlose-simple'

      if (this.isTest) return `test-${name}`

      return name
    },
  },
}
</script>

<style>

</style>
